import React, { useContext, useState} from 'react';
import "./order.css";
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../context/StoreContext';
import { getAPI, postAPI } from '../../components/API/functions';
import { Market } from '../../components/type/Market';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale/fr";
import {addDays} from 'date-fns';
import { Product } from '../../components/type/Product';

interface FormData {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    market: Market|null;
    date: Date | null;
    cartItems: any;
}

interface ErrorForm {
    firstname: string | null;
    lastname: string | null;
    email: string | null;
    phone: string | null;
    market: string | null;
    date: string | null;
    cartItems: string | null;
}

const Order = ()=>{

    const {products,getTotalCartAmount,cartItems} = useContext(StoreContext);
    const navigate = useNavigate();
    const daysLabel = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
    const fee:number = 0;
    
    const [marketsData, setMarketsData] = useState<Market[]>([]);

    const [formData, setFormData] = useState<FormData>({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        market: null,
        date: null,
        cartItems: {}
    });

    const [errorForm, setErrorForm] = useState<ErrorForm>({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        market: '',
        date: '',
        cartItems: ''
    });

    const isMarketDay = (date:Date) => {
        const day = new Date(date).getDay();
        if(!formData.market) return false;
        return formData.market.days.includes(day);
    };
    
    
    const fetchOrder = async () => {
        try {
            const result = await postAPI<any>("api/order", formData);
            if (result.status === "KO") {
                if(result.errors){
                    //error in the form
                    setErrorForm(result.errors);
                }else{
                    //error with request body
                    console.log("Request error : ", result.message);
                }
            } else if (result.status === "OK") {
                // Success order confirmed
                navigate("/confirmation", { state: { order: result.order } }); 
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    
    //FORM VALIDATION & SUBMIT
    const validateName = (type:string,name: string): string | null => {
        const nameRegex = /^[a-zA-ZÀ-ú -]*[A-Za-zÀ-ú][^-]$/;
        if (!name) return `Un ${type} est requis.`;
        if (!nameRegex.test(name)) return `Le ${type} n'est pas valide.`;
        return null;
    }

    const validateEmail = (email: string): string | null  => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) return 'Une adresse email est requise.';
        if (!emailRegex.test(email)) return 'Adresse mail invalide.';
        return null;
    };

    const validatePhone = (phone: string): string | null  => {
        const phoneRegex = /^(?:(?:\+|00)33|\(\+33\)|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
        if (!phone) return 'Un numéro de téléphone est requis.';
        if (!phoneRegex.test(phone)) return 'Numéro de téléphone invalide.';
        return null;
    };

    const validateMarket = (market: Market|null): string | null  => {
        if (!market) return 'Un marché pour retier la commande doit être sélectionné.';
        if (!marketsData.includes(market)) return 'Marché non valide.';
        return null;
    };

    const validateDate = (date: Date|null): string | null => {
        if (!date) return 'Une date de retrait est requise.';
        if (date <= new Date()) return "La date doit être ultérieure à aujourd'hui.";
        return null;
    };

    const validateCartItems = (cartItems:any): string | null => {
        if (!cartItems) return 'Le panier est vide.';
        if (Object.keys(cartItems).length === 0) return "Le panier est vide.";
        for(const key in cartItems){
            if(!products.some((p:Product) => p.id === parseInt(key))) return "Article invalide dans le panier.";
        }
        return null;
    };

    // Handle input changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleMarketChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = event.target.selectedIndex;
      
        // Clear the date first
        setFormData((prevData) => ({ ...prevData, date: null }));
      
        if (selectedIndex === 0) {
          // Reset market if no valid selection
          setFormData((prevData) => ({ ...prevData, market: null }));
          return;
        }
      
        // Assign selected market
        const selectedMarket = marketsData[selectedIndex - 1];
        setFormData((prevData) => ({ ...prevData, market: selectedMarket }));
      };

    const submitForm = async (event:React.FormEvent) => {
        event.preventDefault();

        // Validate the form
        const errors:ErrorForm = {
            firstname: validateName("prénom",formData.firstname),
            lastname: validateName("nom",formData.lastname),
            email: validateEmail(formData.email),
            phone: validatePhone(formData.phone),
            market: validateMarket(formData.market),
            date: validateDate(formData.date),
            cartItems: validateCartItems(formData.cartItems)
        }
        setErrorForm(errors);

        // If there is no error, submit the form
        if (!Object.values(errors).some((error) => error !== null)) {
            //no error
            //console.log("Order valid : ", formData);
            fetchOrder();
        }else{
            console.error("Error in the form", errorForm);
        }

    }
  
    React.useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const result = await getAPI<Market[]>("api/markets");
                setMarketsData(result); // Update the state after sorting
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if(Object.keys(cartItems).length === 0){
            navigate("/panier");
        }else{
            setFormData((prevData) => ({ ...prevData, cartItems: cartItems }));
        }
        fetchMarkets();
        registerLocale("fr-FR", fr);
    }, []);
      
    return(
        <form className="order" id='order' onSubmit={submitForm}>
            <div  className="order-left">
                <p className='title'>Finalisation de la commande</p>
                <p className='sub-title'>Informations personnelles</p>
                <div className="multi-fields">
                    <input name="firstname" title="Veuillez saisir une adresse e-mail valide" type="text" placeholder="Prénom" value={formData.firstname} onChange={handleInputChange} required/>
                    <input name="lastname" type="text" placeholder="Nom" value={formData.lastname} onChange={handleInputChange} required/>
                </div>
                { (errorForm.firstname || errorForm.lastname) && <p className="error">{errorForm.firstname} {errorForm.lastname}</p>}
                <input name="email" type="email" placeholder="Adresse mail" value={formData.email} onChange={handleInputChange} required/>
                {errorForm.email && <p className="error">{errorForm.email}</p>}
                <input name="phone" type="text" placeholder="Numéro de téléphone" value={formData.phone} onChange={handleInputChange} required/>
                {errorForm.phone && <p className="error">{errorForm.phone}</p>}

                <p className='sub-title'>Sélectionner le point de retrais</p>
                <div className="multi-fields2">
                    <select name="market" id="select-markets" onChange={handleMarketChange} required>
                        <option value="">Sélectionner un marché</option>
                        {marketsData.map((data:Market,index:number) => {
                            return(
                                <option key={index}>Marché de {data.market} ({data.days.map((day:number) => daysLabel[day]).join(", " )})
                                </option>
                            )
                        })}
                    </select>
                    <DatePicker
                    name='date'
                    locale={'fr-FR'}
                    className='date-picker'
                    placeholderText="Date de retrait"
                    withPortal 
                    selected={formData.date}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    calendarStartDay={1}
                    excludeDates={[new Date(), addDays(new Date(),1)]}
                    filterDate={isMarketDay} 
                    toggleCalendarOnIconClick 
                    onChange={(dt:any) => {setFormData((prevData) => ({ ...prevData, date: dt }));
                }} required>
                        <div style={{ width: "100%", textAlign: "center", padding: "10px 0", color:`${!formData.market? "red":"black"}`}}>{!formData.market ? "Vous devez d'abord sélectionner un marché" : "Sélectionner une date parmis celle disponibles"}</div>
                    </DatePicker>
                </div>
                {errorForm.market && <p className="error">{errorForm.market}</p>}
                {errorForm.date && <p className="error">{errorForm.date}</p>}

            </div>

            <div className="order-right">
                <div className="cart-total">
                    <h2>Total du panier</h2>
                    <div>
                        <div className="cart-total-details">
                            <p>Sous-total</p>
                            <p>{getTotalCartAmount()}€</p>
                        </div>
                        <hr />
                        <div className="cart-total-details">
                            <p>Frais</p>
                            <p>{fee}€</p>
                        </div>
                        <hr />
                        <div className="cart-total-details">
                            <b>Total</b>
                            <b>{getTotalCartAmount()+fee}€</b>
                        </div>
                    </div>
                    <div className="multi-buttons">
                        <button type='button' className='cancel' onClick={()=>navigate("/panier")}>Retour</button>
                        <button type='submit'>Confirmer la commande</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Order;
