import React from 'react';
import "./footer.css"
import {assets} from "../../assets/assets"
import {Link} from 'react-router-dom';

const Footer = () =>{

    return (
        <div className="footer" id='footer'>
            <div className="footer-content">
                <div className="footer-content-left">
                    <img src={assets.logo} alt='Logo'/>
                    <p>Vos traiteurs vietnamiens fiers de vous présenter leur pays d'origine à travers des plats plein de saveurs dans les marchés normands.</p>
                    <div className="footer-social-icons">
                        <img src={assets.facebook} alt=''/>
                        <img src={assets.twitter} alt=''/>
                        <img src={assets.instagram} alt=''/>
                    </div>
                </div>
                <div className="footer-content-center">
                    <h2>LIENS</h2>
                    <ul>
                        <Link to="/">Accueil</Link>
                        <Link to="/produits">Produits</Link>
                        <Link to="/planning">Planning</Link>
                    </ul>
                </div>
                <div className="footer-content-right">
                    <h2>CONTACT</h2>
                    <ul>
                        <li>(+33)07.71.81.97.98</li>
                        <li>gamthuc112008@gmail.com</li>
                    </ul>
                </div>
            </div>
            <hr/>
            <p className="footer-copyright">Copyright &copy; 2024 - nemhanoi.tech - Tout droit réservé</p>
        
        </div>
    )

}

export default Footer;