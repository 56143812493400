import React from 'react';
import "./header.css"
import {Link} from 'react-router-dom';

type argumentsHeader = {
    title: string;
    position?: string;
    image: string;
    description: string;
    button_link: string;
    button_text: string;
}

const Header = ({title,position,image,description,button_link,button_text}:argumentsHeader) => {

    return(
        <div className={`header ${image}`} >
            <div className={`header-contents ${position}`}>
                <h2>{title}</h2>
                <p>{description}</p>
                <Link to={button_link}>
                    <button>{button_text}</button>
                </Link>
            </div>
        </div>
    );
}

export default Header;