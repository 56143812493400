import {useContext,  useEffect, useState,} from "react";
import "./confirmation.css";
import {assets} from "../../assets/assets";
import { useLocation,useNavigate } from "react-router-dom";
import { StoreContext } from '../../context/StoreContext';
import { Product } from "../../components/type/Product";

type Order = {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    market: any;
    date: Date;
    cartItems: any;
};

const Confirmation = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [total, setTotal] = useState<number>(0);
    const {products, clearCart, getTotalCartAmount} = useContext(StoreContext);
    const [order, setOrder] = useState<Order>({
        id: 0,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        market: "",
        date: new Date(),
        cartItems:{}
    });

    const handlePrint = () => {
        window.print();
    };


    useEffect(() => {
        if (!order.id && location.state?.order && products.length > 0) {
          setOrder(location.state.order);
          setOrder((prevData) => ({...prevData,date: new Date(prevData.date)}));
        } else {
          navigate("/panier");
        }
    
        setTotal(getTotalCartAmount());
        clearCart(); // Clear cart after order confirmation
      }, []);

    return (
        <div className="confirmation">
            <div className="order-confirmation">
                <img src={assets.confirmation} alt="logo" />
                <div className="thank-order">
                    <p>Commande n°{order.id}</p>
                    <h2>Merci, {order.firstname}</h2>
                </div>
            </div>
            <h2>Paiement et retrait de la commande</h2>
            <div className="order-location">
                <p>Date de retrait de la commande le <b>{order.date.toLocaleDateString()}</b></p>
                <p>Marché de <b>{order.market.market}</b> au stand Nem Hanoï</p>
                <p className="info">La commande a été validée, mais le montant devra être réglé au stand Nem Hanoï le jour du retrait.</p>
            </div>
            <h2>Détails de la commande</h2>
            <div className="order-details">
                { Object.keys(order.cartItems).length > 0 && Object.keys(order.cartItems).map((item:any,index:number) => {
                    const product:Product = products.find((prod:Product) => prod.id === Number(item));
                    return(
                        <div key={index} className="product-details">
                            <img src={product.image} alt={product.name} />
                            <p>{product.name}</p>
                            <p>{product.price}€</p>
                            <p>{order.cartItems[item]}</p>
                            <p>{product.price*order.cartItems[item]}€</p>
                        </div>
                    )
                })}
                <hr />
                <h3>Total : {total}€</h3>
            </div>
            <div className="multi-buttons">
                <button className="print" onClick={()=>handlePrint()}>Imprimer</button>
                <button className="leave" onClick={()=>navigate("/")}>Retour à l'accueil</button>
            </div>
            
            <p className="contact">Pour tous renseignements ou toutes modifications concernant la commande, veuillez nous contacter à l'adresse suivante:<br/> 
            <a href="mailto:gamthuc112008@gmail.com"> <b>gamthuc112008@gmail.com</b></a> ou au numéro suivant: <b>(+33)07.71.81.97.98</b></p>     
        </div>
        
    )
};

export default Confirmation;

