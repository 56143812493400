import { useContext } from 'react';
import "./cart.css";
import { useNavigate } from 'react-router-dom';
import {StoreContext} from "../../context/StoreContext";
import {Product} from "../../components/type/Product";
import {assets} from "../../assets/assets";

const Cart = () => {

    const {cartItems, products, addToCart, subFromCart, setToCart, removeFromCart, getTotalCartAmount} = useContext(StoreContext);
    const navigate = useNavigate();
    const fee:number = 0;

    const CheckCart = () =>{
        if(Object.keys(cartItems).length > 0){
            navigate("/commande");
        }else{
            console.log("Panier vide ! Impossible de continuer !")
        }
    }

    return(
        <div className="cart">
            <h1>Mon Panier</h1>
            <div className="cart-items">
                <div className="cart-items-title">
                    <p>Produit</p>
                    <p>Nom</p>
                    <p>Prix</p>
                    <p>Quantité</p>
                    <p>Total</p>
                </div>
                <br/>
                <hr/>
                {Object.keys(cartItems).length > 0 ? 
                (Object.keys(cartItems).map((item:any,index:number) => {
                    const product:Product = products.find((product:Product) => product.id === Number(item));
                    if(!product){ return null; }
                    return(
                            <div key={index}>
                                <div className="cart-items-title cart-items-item">
                                    <img src={product.image} alt={product.name}/>
                                    <p>{product.name}</p>
                                    <p>{product.price}€</p>
                                    <div className="cart-item-quantity">
                                        <img onClick={()=>subFromCart(product.id)} src={assets.remove_red} alt="decrease"/>
                                        <input min="0" max="200" type="number" onChange={(e)=>{if(e.target.value !=="" && Number(e.target.value)>=0){setToCart(product.id, Number(e.target.value))}}} value={cartItems[product.id]}></input>
                                        <img onClick={()=>addToCart(product.id)} src={assets.add_green} alt="increase"/>
                                    </div>
                                    <p>{product.price*cartItems[product.id]}€</p>
                                    <img className="cross" alt="bin" src={assets.bin} onClick={()=>removeFromCart(product.id)}></img>
                                </div>
                                <hr/>
                            </div>
                        )
                })) : 
                (<>
                <p>Votre panier est vide</p>
                <hr/></>
                )}
                <button className={`${Object.keys(cartItems).length<=0 && "active"}`} onClick={()=>navigate("/produits")}>Ajouter des produits ?</button>
            </div>
            <div className="cart-bottom">
                <div className="cart-promocode">
                    <div>
                        <p>Si vous avez un code promotionnel, entrez le ici </p>
                        <div className="cart-promocode-input">
                            <input type="text" placeholder="Code promo"/>
                            <button>Ajouter</button>
                        </div>
                    </div>
                </div>
                <div className="cart-total">
                    <h2>Panier Total</h2>
                    <div>
                        <div className="cart-total-details">
                            <p>Sous-total</p>
                            <p>{getTotalCartAmount()}€</p>
                        </div>
                        <hr />
                        <div className="cart-total-details">
                            <p>Frais</p>
                            <p>{fee}€</p>
                        </div>
                        <hr />
                        <div className="cart-total-details">
                            <b>Total</b>
                            <b>{getTotalCartAmount()+fee}€</b>
                        </div>
                    </div>
                    <button disabled={(Object.keys(cartItems).length<=0)} onClick={()=>{CheckCart()}}>Valider le panier</button>
                </div>
            </div>    
        </div>
        
    )
}

export default Cart;