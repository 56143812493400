import React from 'react';
import './productsdisplay.css';
import {Product} from '../type/Product';
import ProductItem from '../ProductItem/ProductItem';

type argumentsProductDisplay = {
    products:Product[];
    categories:string[];
}

const ProductsDisplay = ({products,categories}:argumentsProductDisplay) => {

    return(
        <div className="products-display" id="products-display">
            {/*<h2>Voici les produits correspondants :</h2>*/}
            <div className="products-display-list">
                {products.filter( product => categories.length >0 ? product.categories.some(r=> categories.includes(r)) : true).map((product,index) => (
                    <ProductItem key={index} product={product}/>
                ))}
            </div>
        </div>
    );

}

export default ProductsDisplay;