import React from 'react';
import "./notfound.css"
import {Link} from 'react-router-dom';


const NotFound = () => {
    return (
        <div className="notfound">
            <h1>Erreur 404</h1>
            <h2>La page que vous cherchez n'existe pas !</h2>
            <Link to="/">
                <button>Retour à l'accueil</button>
            </Link>
        </div>
    );
};

export default NotFound;