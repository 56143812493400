import { BASE_URL } from "../../constants";

export async function getAPI<T>(url: string, retries: number=3): Promise<T> {
  let retryCount = 0;
  while (retryCount < retries) {
    try {
      
      const response = await fetch(`${BASE_URL}/${url}`, { method: 'GET' });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      //console.log(response);
      return await response.json() as T;
    } catch (error) {
      retryCount++;
      console.log(`Retrying fetch API (${retryCount}/${retries})...`);
    }
  }

  throw new Error(`Failed after ${retries} retries.`);
}

export async function postAPI<T>(url: string, body?: Record<string, any>, retries: number=3): Promise<T> {
  let retryCount = 0;
  while (retryCount < retries) {
    try {
      const options: RequestInit = { method: 'POST' };
      if (body) {
        options.body = JSON.stringify(body);
        options.headers = {
          'Content-Type': "application/json"
        };
        //console.log(options);
      }
      const response = await fetch(`${BASE_URL}/${url}`, options);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      //console.log(response);
      return await response.json() as T;
    } catch (error) {
      retryCount++;
      console.log(`Retrying fetch API (${retryCount}/${retries})...`);
    }
  }

  throw new Error(`Failed after ${retries} retries.`);
}
