import Header from "../../components/Header/Header";

const Home = () => {
    
  return (
    <div>
      <Header title="Nos saveurs du Vietnam" image='header-bg1' description="M. et Mme DONG sont ravis de vous faire découvrir les saveurs du Vietnam à travers une variété de plats typiques d'Asie, proposés sur les marchés normands !" button_link="/produits" button_text="Voir nos plats"/>
      <Header title="Où nous retrouver !" position={"right"} image='header-bg2' description="Afficher notre itinéraire avec tous les marchés dans lesquelles nous vendons !" button_link="/planning" button_text="Voir le planning"/>
    </div>
  );
}

export default Home;