import { useEffect,useState,createContext } from "react";
import { Product } from "../components/type/Product";
import { getAPI} from "../components/API/functions";
import { BASE_URL } from "../constants";

export const StoreContext = createContext<any>(null);

const StoreContextProvider = (props:any) =>{

    const [products, setProducts] = useState<Product[]>([]);
    const [cartItems, setCartItems] = useState<any>({});


    const addToCart = (itemId: number) => {
        setCartItems((prev: any) => {
          const updatedCart = {
            ...prev,
            [itemId]: (prev[itemId] || 0) + 1,
          };
          saveCartLocalStorage(updatedCart);
          return updatedCart;
        });
      };

    const subFromCart = (itemId: number) => {
        setCartItems((prev: any) => {
          const updatedCart:any = { ...prev };
          if (updatedCart[itemId] <= 1) {
            delete updatedCart[itemId];
          } else {
            updatedCart[itemId] -= 1;
          }
          saveCartLocalStorage(updatedCart);
          return updatedCart;
        });
    };

    const setToCart = (itemId: number,quantity:number) => {
        if(quantity <= 0){
            removeFromCart(itemId);
            return;
        }
        setCartItems((prev: any) => {
          const updatedCart = {
            ...prev,
            [itemId]: quantity,
          };
          saveCartLocalStorage(updatedCart);
          return updatedCart;
        });
      };

    const removeFromCart = (itemId:number) => {
        const updatedCartItems = { ...cartItems };
        delete updatedCartItems[itemId];
        setCartItems(updatedCartItems);
        saveCartLocalStorage(updatedCartItems);
    }
    
    const clearCart = () => {
        setCartItems({});
        saveCartLocalStorage({});
    }


    const getCartFromLocalStorage = ()=>{
        if (typeof(Storage) !== "undefined") {
            //console.log("Getting cart items from local storage");
            if(localStorage.getItem('user_cart')){
                //console.log(localStorage.getItem('user_cart'))
                setCartItems(JSON.parse(localStorage.getItem('user_cart') || '{}'));
            }
        }
    }

    const saveCartLocalStorage = (cart:any) => {
        if (typeof(Storage) !== "undefined") {
            //console.log("Saving cart items to local storage");
            localStorage.setItem('user_cart', JSON.stringify(cart));
        }
    }



    const getTotalCartAmount = () => {
        let totalAmount = 0;
        for(const item in cartItems){
            if(cartItems[item] > 0) {
                let itemInfo:any = products.find((product:Product) => String(product.id) === item);
                totalAmount += itemInfo?.price * cartItems[item];
            };
        }

        return totalAmount;
    }

    const fetchProducts = async () => {
        try {
        var result = await getAPI<Product[]>("api/products");
        //console.log(result);
        for(var product of result){
            product.image = BASE_URL+"/images/"+ product.image;
        }
        setProducts(result); 
        } catch (error) {
        console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchProducts();
        getCartFromLocalStorage();
    }, []);

    const contextValue = {
        products,
        cartItems,
        setCartItems,
        addToCart,
        subFromCart,
        setToCart,
        removeFromCart,
        clearCart,
        getTotalCartAmount
    }
    return(
        <StoreContext.Provider value={contextValue}>
            {props.children}
        </StoreContext.Provider>
    )

}

export default StoreContextProvider;    