import React from 'react';
import "./menudisplay.css"


type MenuDisplayProps = {
    categories: string[];
    setCategories:(value: string[]) => void;
};



const MenuDisplay = ({ categories, setCategories }: MenuDisplayProps) => {

    const menu_list = ["Nems", "Entrées", "Plats", "Vapeur", "Salades", "Soupes", "Desserts", "Piquant", "Chaud", "Froid"];

    const handleCategoryClick = (category: string) => {
        if(categories.includes(category)){
            setCategories(categories.filter((item)=>item!==category));
            //console.log(`Removed ${category} filter`);
        }else{
            setCategories([...categories, category]);
            //console.log(`Added ${category} filter`);
        }
    }

    return (
        <div className="menu-display" id="menu-display">
            <h1>Explorer nos produits</h1>
            <p className='menu-text'>Vous pouvez filtrer les produits en fonction des catégories suivantes :</p>
            <div className="menu-list">
                {menu_list.map((item,index)=>{
                    return(
                        <button key={index} onClick={()=>handleCategoryClick(item)} className={`menu-list-item  ${categories.includes(item) ? 'active' : ''}`}>{item}</button>
                    );
                })}
            </div>
            <hr/>
        </div>

    );
}

export default MenuDisplay;