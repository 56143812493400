import React, {useContext} from "react";
import "./productitem.css";
import {Product} from "../type/Product";
import {assets} from "../../assets/assets";
import {StoreContext} from "../../context/StoreContext";

type argumentsProductItem = {
    product:Product;
}

const ProductItem = ({product}:argumentsProductItem) => {

    const {addToCart,subFromCart,cartItems} =useContext(StoreContext);

    return (
        <div className="product-item">
            <div className="product-item-image-container">
                <img className="product-item-image" src={product.image} alt={product.name}/>
                {!cartItems[product.id]
                ?<img className="product-item-add" onClick={()=>addToCart(product.id)} src={assets.add} alt="add"/>
                : <div className="product-item-counter">
                    <img onClick={()=>subFromCart(product.id)} src={assets.remove_red} alt="decrease"/>
                    <p>{cartItems[product.id]}</p>
                    <img onClick={()=>addToCart(product.id)} src={assets.add_green} alt="increase"/>
                </div>
                }
            </div>
            <div className="product-item-info">
                <div className="product-item-name-category">
                    <p>{product.name}</p>
                    <img src={product.categories.includes("Chaud") ? assets.hot : assets.cold} alt="hot or cold"/>
                </div>
                <p className="product-item-desc">{product.description}</p>
                <div className="product-item-nb-price">
                    <div className="product-item-nb">{product.quantity == null ?"Unité" : "Boîte de "+ product.quantity }</div> 
                    <div className="product-item-price">{product.price}€</div>
                </div>
            </div>
        </div>
    );
}

export default ProductItem;