import React, {useState} from 'react';
import "./weekdisplay.css";
import {assets} from "../../assets/assets";
import {getAPI} from "../API/functions"
import { WeekData } from '../type/WeekData';

const WeekDisplay = () => {

    const [weekData, setWeekData] = useState<WeekData[]>([]);

    const fetchPlanning = async () => {
        try {
            const result = await getAPI<WeekData[]>("api/planning");
            setWeekData(result); // Update the state after sorting
        } catch (error) {
            console.error("Error fetching data:", error);
        }
      };
  
      React.useEffect(() => {
        fetchPlanning();
      }, []);


    return(
        <div className='week-display'>
            {weekData.map((day, index) => (
                <div key={index} className={`day ${index===0? "day-active" : ""}`}>
                    <div className="day-title">
                        <h2>{day.day}</h2>
                    </div>
                    <hr/>
                    <div className="day-content">
                        {day.markets.length <= 0 ? 
                        (
                        <div className="day-market">
                            <h3>Journée au fourneau</h3>
                        </div>
                        ) : day.markets.map((market,index2) =>(
                            <div key={index2} className="day-market">
                                <div className="city">
                                    <img src={assets.position} alt="Marqueur"/>
                                    <h3>{market.city}</h3>
                                </div>
                                <div className="time">
                                    <img src={assets.time} alt="Horloge"/>
                                    <h3>{market.time}</h3>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>


            ))}

        </div>
    )

}

export default WeekDisplay;