import logo from "./Logo.png"
import search from "./search.png"
import hot from "./hot.png"
import cold from "./cold.png"
import facebook from "./facebook.png"
import twitter from "./twitter.png"
import instagram from "./instagram.png"
import position from "./position.png"
import time from "./time.png"
import menu from "./menu.png"
import exit from "./exit.png"
import add from "./add.png"
import add_green from "./add_green.png"
import remove_red from "./remove_red.png"
import panier from "./panier.png"
import bin from "./bin.png"
import confirmation from "./confirmation.png"

export const assets ={
    logo,search,hot,cold,
    facebook,twitter,instagram,
    position,time,menu,exit,add,
    add_green,remove_red,panier,
    bin,confirmation
    
};