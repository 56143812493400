import {useState, useContext} from "react";
import "./products.css";
import MenuDisplay from "../../components/MenuDisplay/MenuDisplay";
import ProductsDisplay from "../../components/ProductsDisplay/ProductsDisplay";
import { StoreContext } from "../../context/StoreContext";


const Products = () => {

    const [categories, setCategories] = useState<string[]>([]);
    const {products} = useContext(StoreContext);
    
    
    return (

        <div>
            <MenuDisplay categories={categories} setCategories={setCategories}/>
            <ProductsDisplay products={products} categories={categories}/>
        </div>
    );
};

export default Products;