import React from "react";
import "./planning.css";
import WeekDisplay from "../../components/WeekDisplay/WeekDisplay";


const Planning = () => {

    return (
        <div className="planning">
            <h1>Planning</h1>
            <p>Retrouvez ci-dessous le planning des marchés de chaque semaine.</p>
            <hr/>
            <WeekDisplay/>
        </div>
    );
}

export default Planning;